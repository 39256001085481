<template>
  <!-- hours? -->
  <div 
    id="cpm" 
  >
    <!-- <segment-filter
      :items="segments"
      :selected="segment"
      @select="onSegmentSelection"
      @save="onSegmentSave"
    /> -->
    <v-container 
      fluid 
      class="charts-container pa-0"
    >
      <v-row>
        <v-col
          v-if="cards.daily.toggle"
        >
          <data-card
            :title="$t(cards.daily.title, { metric: metricLabel(metric) })"
            :loading="cards.daily.loading"
            :chart="cards.daily.chart"
            :numbers="cards.daily.numbers"
            max-height="68vh"
            class="daily"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col 
          v-if="cards.location.toggle"
          xs="12"
          :sm="cards.hourly.toggle ? 6 : 12"
          :md="cards.hourly.toggle ? 5 : 12"
        >
          <data-location-card
            :geofences="geofences"
            :pois="pois"
            :metric="metric"
            :cities="cities"
            :city="city"
            :layer="layer"
            :layers="layerOptions"
            max-height="68vh"
            class="location"
            @layer-change="onLayerChange"
            @city-change="onCityChange"
          >
            <template v-slot:title>
              <v-card-actions class="pt-4">
                <h2 class="card-title font-weight-medium pa-1 pl-2">
                  {{ $t(cards.location.title, { metric: metricLabel(metric) }) }}
                </h2>
              </v-card-actions>
            </template>
          </data-location-card>
        </v-col>
        <v-col
          v-if="cards.hourly.toggle"
          xs="12"
          :sm="cards.location.toggle ? 6 : 12"
          :md="cards.location.toggle ? 7 : 12"
        >
          <data-card
            :title="$t(cards.hourly.title, { metric: metricLabel(metric) })"
            :loading="cards.hourly.loading"
            :chart="cards.hourly.chart"
            max-height="68vh"
            class="hourly"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import services from '@/services';
  import { report as getData, geofences as getGeofences } from '@/api/dashboard';
  import { sync } from 'vuex-pathify'

  const diageoPois = () => import('@/assets/data/diageo-pois.json');
  const descomplica57Pois = () => import('@/assets/data/descomplica-57-pois.json');
  const descomplica40Pois = () => import('@/assets/data/descomplica-40-pois.json');
  const descomplica16Pois = () => import('@/assets/data/descomplica-16-pois.json');
  const boticarioPois = () => import('@/assets/data/boticario-pois.json');
  const boticarioPois283 = () => import('@/assets/data/boticario-pois-283.json');
  const boticarioPois438 = () => import('@/assets/data/boticario-pois-438.json');
  const boticarioPois481 = () => import('@/assets/data/boticario-pois-481.json');
  const boticarioPois513 = () => import('@/assets/data/boticario-pois-513.json');
  const boticarioPois514 = () => import('@/assets/data/boticario-pois-514.json');
  const boticarioPois540 = () => import('@/assets/data/boticario-pois-540.json');

  export default {
    name: 'Airtime',

    props: {
      dataKey: {
        type: String,
        default: null
      },
      period: {
        type: Array,
        default: () => null
      },
      duration: {
        type: Array,
        default: () => null
      },
      scope: {
        type: Number,
        default: 0
      },
      children: {
        type: Array,
        default: () => null
      },
      segment: {
        type: Number,
        default: 0
      },
      segments: {
        type: Object,
        default: () => {}
      },
      user: {
        type: Object,
        default: () => {}
      },
      stored: {
        type: Object,
        default: () => {}
      },
      budget: {
        type: Number,
        default: 0
      },
      layers: {
        type: [Array, Object],
        default: () => [],
      },
      layer: {
        type: String,
        default: 'geofences'
      },
      cities: {
        type: Object,
        default: () => {}
      },
      city: {
        type: Object,
        default: () => null
      }
    },

    data: () => ({
      metric: 'airtime',
      cards: {
        daily: {
          toggle: true,
          title: 'dashboard.charts.daily.label',
          numbers: {
            total: {
              title: 'dashboard.charts.daily.total',
              hint: null,
              value: {
                data: null,
                format: '0[.]0 a',
                color: 'primary'
              },
              secondary: {
                data: null,
                format: '0,0.0 %',
                colored: true
              }
            },
            weekly: {
              title: 'dashboard.charts.daily.weekly_avg',
              hint: null,
              value: {
                data: null,
                format: '0[.]0 a',
                color: 'primary'
              },
              secondary: {
                data: null,
                format: '0,0.0 %',
                colored: true
              }
            },
            daily: {
              title: 'dashboard.charts.daily.daily_avg',
              hint: null,
              value: {
                data: null,
                format: '0[.]0 a',
                color: 'primary'
              },
              secondary: {
                data: null,
                format: '0,0.0 %',
                colored: true
              }
            },
          },
          chart: {
            type: 'AreaChart',
            data: null,
            options: {
              hAxis: {
                format: 'percent'
              },
              series: {
                1: { lineDashStyle: [4, 4], pointSize: 0 },
              },
              colors: [
                '#476CFF',
                '#DEDEDE'
              ]
            }
          },
          loading: false,
          empty: false,
        },
        hourly: {
          toggle: true,
          title: 'dashboard.charts.hourly.label',
          numbers: null,
          chart: {
            type: 'HourlyHeatmap',
            label: null,
            format: '0[.]0 a',
            data: null,
          },
          audience: {
            data: null,
            key: null,
          },
          spent: {
            data: null,
            key: null,
          },
          loading: false,
          empty: false,
        },
        location: {
          toggle: true,
          title: 'dashboard.charts.location.label',
          numbers: null,
          loading: false,
          empty: false,
        },
      },
      geofences: {
        toggle: false,
        data: null,
        type: 'geofences'
      },
      pois: {
        toggle: false,
        loading: false,
        point: false,
        data: [],
        clients: {},
      },
      pipeline: {
        data: { 
          get: 'getData',
          set: 'setData',
          dimensions: ['daily', 'geofences', 'hourly'],
          type: 6,
          key: null,
          loading: false,
          updated: false
        },
        pois: { 
          get: 'getGeofences',
          set: 'setPois',
          type: 7,
          key: null,
          loading: false,
          updated: false,
        },
      },
      source: {
        min: null,
        max: null,
        prototype: {
          daily: null,
          hourly: null,
          geofences: [],
          pois: []
        }
      },
    }),

    components: {
      // SegmentFilter: () => import('@/components/SegmentFilter'),
      DataCard: () => import('@/components/DataCard'),
      DataLocationCard: () => import('@/components/DataLocationCard'),
    },

    computed: {
      toast: sync('app/toast'),

      loading () {
        return _.some(this.pipeline, 'loading');
      },
      updated () {
        return _.every(this.pipeline, 'updated');
      },

      layerOptions () {
        return _.map(this.layers, layer => {
          return {
            ...layer,
            disabled: (layer.value!='cities'&&this.city==null)||(layer.value=='pois'&&!this.pipeline.pois.loading&&_.isEmpty(this.pois.data))
          }
        })
      },

      supported () {
        const cities = this.cities;
        return _.reduce(cities, (geofences, city) => {
          _.each(city.zones, zone => {
            _.each(zone.geofences, g => {
              geofences[g.id] = {
                id: g.id,
                title: g.title,
                zone: { id: zone.id, title: zone.title },
                city: { id: city.id, title: city.title },
                state: _.clone(city.state),
                country: _.clone(city.country),
              }
            });
          })
          return geofences;
        }, {});
      },
    },

    watch: {
      layer : {
        immediate: true,
        handler (layer) {
          // this.toggleLayer(layer);
        }
      },
      $route: {
        immediate: true,
        deep: true,
        handler(route) {
          const charts= _.has(route.query, 'charts') ? _.split(route.query.charts, ',') : []
          if (charts.length>0) {
            _.each(this.cards, (card, key) => {
              card.toggle= _.indexOf(charts, key) >=0;
            })
          }
        }
      },
      dataKey: {
        immediate: true,
        handler (k) {
          if (this.period!=null) {
            this.clearData();
            this.updateView();
          }
          if (!this.store) this.getStoredData();
        }
      },
      google: {
        immediate: true,
        handler (v) {
          if (v!=null) this.clientsPois();
        }
      },
      updated: {
        immediate: true,
        handler (b) {
          const data = { geofences: _.clone(this.geofences), pois: _.clone(this.pois) };
          this.$emit('update', b, data);
        }
      }
    },

    methods: {
      ...services,
      getData,
      getGeofences,

      setData (key, source, store) {
        console.log(key, source);
        this.pipeline.data.key = key;
        let { daily, geofences, hourly } = source;
        this.source[key]['daily'] = daily;
        this.source[key]['geofences'] = geofences;
        this.source[key]['hourly'] = hourly;

        if (_.every(source, s => _.isEmpty(s))) {
          this.toggleToast(
            true,
            this.$t('dashboard.no_data'),
            5000,
            false,
          );
          return null;
        }else{
          daily = Object.freeze(_.orderBy(daily, ['day'], ['asc']));
        }

        // set daily
        if (!_.isNil(daily)) this.setDaily(daily);

        // set geofences
        if (!_.isNil(geofences)) this.setGeofences(geofences);
        
        // set hourly
        if (!_.isNil(hourly)) this.setHourly(hourly);
        
        if (_.isNil(store)||store) {
          this.storeData('daily', daily);
          this.storeData('geofences', geofences);
          this.storeData('hourly', hourly);
        }

        this.loadNext();
      },

      setDaily (source) {
        const target = this.cards.daily;

        // get info
        source = _.orderBy(source, ['day'], ['asc']);
        const period = {
          start: this.$moment(_.first(source).day),
          end: this.$moment(_.last(source).day),
          days: [],
          data: [],
          total: 0
        }
        const range = this.$moment(period.end).diff(period.start, 'days')+1;
        const previous = {
          start: this.$moment(period.start).subtract(range, 'days'),
          end: this.$moment(period.start).subtract(1, 'days'),
          days: [],
          total: 0
        }
        
        // total hours
        _.reduce(source, (sum, d) => {
          const fact = this.$moment(d.day);
          if (fact.isBetween(previous.start, previous.end, 'day', [])) {
            previous.days.push(d.day);
            previous.total += d.hours / 60 / 60;
          }else if (fact.isBetween(period.start, period.end, 'day', [])) {
            period.days.push(d.day);
            period.data.push(d);
            period.total += d.hours / 60 / 60;
          }
          return sum + d.hours / 60 / 60;
        }, 0);
        period.days = _.uniq(period.days);
        previous.days = _.uniq(previous.days);

        // hours over time
        let chart = _.map(_.groupBy(_.sortBy(period.data, ['day', 'asc']), (p) => {
          return p.day;
        }), (group, day) => {
          const dailyTotal = _.reduce(group, (sum, g) => {
            return sum + (g.hours / 60 / 60);
          }, 0);
          return [
            { v: day, f: this.$moment(day).format('DD/MM') + ' ' + this.$moment(day).format('ddd').toUpperCase() },
            dailyTotal
          ]
        });
        chart.unshift([this.$tc('general.day'), this.metricLabel(this.metric)]);

        target.chart.data = chart;
        const total = period.total;
        const daily = total / range;
        const weekly = range<7 ? total : daily * 7;
        target.numbers.total.value.data = total;
        target.numbers.weekly.value.data = weekly;
        target.numbers.daily.value.data = daily;
        // if (previous.days.length==period.days.length) {
        //   target.number.secondary.data = (period.total - previous.total) / period.total;
        // }else{
        //   target.number.secondary.data = null;
        // }
        console.log(range, period.days, weekly, daily, period.total, previous.total);

        target.updated = true;
      },

      setGeofences (source) {
        if (_.isEmpty(source)) {
          this.toggleToast(
            true,
            this.$t('dashboard.no_data'),
            5000,
            false,
          );
          return null;
        }else{
          source = Object.freeze(_.orderBy(_.reject(source, ['id', 0]), ['day'], ['asc']));
        }
        
        // set map geofences/zone data
        this.geofences.data = _.reduce(_.keyBy(source, 'id'), (processed, geofence) => {
          const { id, title, audience, impressions, spent, cpm, hours } = geofence;
          if (_.has(this.supported, id)) {
            const info = this.supported[id];
            processed[id] = {
              id,
              title,
              audience, 
              impressions, 
              spent, 
              cpm,
              airtime: hours / 60 / 60,
              ...info,
            }
          }
          return processed
        }, {});
        
      },

      setPois (key, source, store) {
        this.pipeline.pois.key = key;
        this.source[key].pois = source;

        if (_.isEmpty(source)) {
          // this.toggleToast(
          //   true,
          //   'Não há dados de Pontos de Interesse para a ' + this.getDictionary('campaign') + ' no período selecionado.',
          //   5000,
          //   false,
          // );
          return null;
        }else{
          source = Object.freeze(_.orderBy(_.reject(source, ['id', 0]), ['day'], ['asc']));
        }
        
        // set map pois data
        this.pois.data = _.concat(_.map(source, g => {
          const { id, title, type, use, audience, impressions, spent, cpm, hours } = g;
          const { title: city, state, country } = this.cities[g.city_id];
          return {
            id,
            title,
            type,
            use,
            audience, 
            impressions, 
            spent, 
            cpm,
            airtime: hours / 60 / 60,
            url: _.has(g, 'url') ? g.url : null,
            city: {
              id: g.city_id,
              city,
            },
            state,
            country
          }
        }, this.getClientPois()));

        if (_.isNil(store)||store) this.storeData('pois', source);

        this.loadNext();
      },

      setHourly (source) {

        let data = _.times(24, (h) => {
          return _.times(7, (d) => {
            return 0;
          });
        });

        // map data to structure
        _.map(source, (p) => {
          data[p.hour][p.weekday-1] = p.hours / 60 / 60;
        });

        this.cards.hourly.chart.data = data;
      },

      storeData (dimension, source) {
        console.log('store', dimension);
        const key = this.dataKey.toString();
        this.$emit('store-data', {
          key, 
          dimension,
          source
        })
      },

      getStoredData (key, dimensions) {
        const stored = _.reduce(_.isArray(dimensions) ? dimensions : [dimensions], (stored, d) => {
          if (_.has(this.stored, d) && this.stored[d].key==key) {
            stored.store[d] = this.stored[d].source;
            stored.timestamp = this.$moment(stored.timestamp).isAfter(this.stored[d].timestamp) ? stored.timestamp : this.stored[d].timestamp;
          }
          return stored;
        }, { store: {}, timestamp: 0 });
        return { store: _.isEmpty(stored.store) ? null : (_.isArray(dimensions) ? stored.store : stored.store[dimensions]), timestamp: stored.timestamp };
      },

      onLayerChange (layer) {
        this.$emit('layer-change', layer);
      },
      onCityChange (city) {
        this.$emit('city-change', city);
      },

      updateView () {
        this.loadNext();
      },

      clearData () {
        _.each(this.pipeline, p => {
          p.updated = false;
        });
        this.cards.daily.chart.data = null;
        this.cards.daily.numbers.total.value.data = null;
        this.cards.daily.numbers.weekly.value.data = null;
        this.cards.daily.numbers.daily.value.data = null;
        this.cards.hourly.chart.data = null;
        this.geofences.data = null;
        this.pois.data = null;
      },

      loadNext () {
        const $ = this;
        const s = this.dataKey.toString();
        if (!_.has(this.source, s)) {
          this.source[s] = Object.assign({}, this.source.prototype);
        }
        _.each(this.pipeline, (p, k) => {
          const { store, timestamp } = $.getStoredData(s, _.has(p, 'dimensions') ? p.dimensions : k);
          if (p.key!=s||!p.updated&&!p.loading) {
            if (!_.isNil(store)) {
              const updated = (_.has(p, 'dimensions') ? _.size(store)==_.size(p.dimensions) : true )&&this.$moment().diff(this.$moment(timestamp), 'minutes')<15;
              console.log('stored', k, updated);
              p.updated = updated;
              p.loading = !updated;
              $[p.set](s, store, false);
              if (updated) return false;
            }
            p.loading = true;
            $[p.get](
              $.user.auth.token,
              $.dataKey.toString(),
              $.scope, 
              $.children, 
              $.period,
              p.type,
              (key, data) => {
                if (key==s) {
                  p.updated = true;
                  $[p.set](key, data);
                }
              },
              (error) => {
                const msg = this.$t('messages.waiting_response');
                setTimeout(($) => {
                  this.handleError(error, msg, [
                    true,
                    msg,
                    7000,
                    false
                  ]);
                  setTimeout(($) => $.loadNext(), 5000, $);
                }, 5000, this);
              },
              () => {
                p.loading = false;
              }
            );
            return false;
          }
        });
      },

      getClientPois () {
        let data = [];
        _.each(this.pois.clients, (client, c) => {
          if (c==this.scope) {
            this.pois.point = true;
            data = _.concat(data, _.values(_.reduce(client, (process, p, i) => {
              if (!_.has(p, 'timestamp')||(this.$moment(p.dia).isSameOrAfter(this.period[0])&&this.$moment(p.dia).isSameOrBefore(this.period[1]))) {
                if (!_.has(p, 'id')||!_.has(process, p.id)) {
                  process[_.has(p, 'id') ? p.id : i] = {
                    id: _.has(p, 'id') ? p.id : i, 
                    title: p.poi,
                    position: {
                      lat: p.lat,
                      lng: p.log
                    },
                    type: 'POI',
                    radius: p.raio,
                    audience: p.audiencia,
                    impressions: p.exibicoes,
                    spent: p.realizado,
                  };
                }else{
                  process[p.id] = _.assign(process[p.id], {
                    audience: process[p.id].audience + p.audiencia,
                    impressions: process[p.id].impressions + p.exibicoes,
                    spent: process[p.id].spent + p.realizado,
                  });
                }
              }
              return process;
            }, {})));
          } 
        })
        return data;
      },

      clientsPois () {
        diageoPois().then(async (data) => {
          this.fetchPois('74', data);
        });
        descomplica57Pois().then(async (data) => {
          this.fetchPois('57', data);
        });
        descomplica40Pois().then(async (data) => {
          this.fetchPois('40', data);
        });
        descomplica16Pois().then(async (data) => {
          this.fetchPois('16', data);
        });
        boticarioPois().then(async (data) => {
          this.fetchPois('257', data);
        });
        boticarioPois283().then(async (data) => {
          this.fetchPois('283', data);
        });
        boticarioPois438().then(async (data) => {
          this.fetchPois('438', data);
        });
        boticarioPois481().then(async (data) => {
          this.fetchPois('481', data);
        });
        boticarioPois513().then(async (data) => {
          this.fetchPois('513', data);
        });
        boticarioPois514().then(async (data) => {
          this.fetchPois('514', data);
        });
        boticarioPois540().then(async (data) => {
          this.fetchPois('540', data);
        });
      },
      async fetchPois (key, data) {
        // console.log(`POIs ${key} carregados`);
        const $ = this;
        let pois = data.default;
        
        if (_.some(pois, p => _.isString(p) || (p.lat==null||p.log==null))) {
          const geocoder = new $.google.maps.Geocoder();
          if (_.some(pois, p => _.isString(p))) pois = _.map(pois, (p,i) => {
            return {
              "id": _.has(p, 'id') ? p.id : i,
              "poi": p,
              "lat": null,
              "log": null,
              "exibicoes": 1,
              "audiencia": 1,
              "realizado": 1,
              "raio": 1000
            }
          })
          for await (let p of pois) {
            if (p.lat==null||p.log==null) {
              try {
                await this.pause(250);
                const position = await this.getAddressLatLng(geocoder, p.poi);
                p.lat = position.lat(); 
                p.log = position.lng();
                console.log(p);
              } catch (err) {
                console.warn(err);
              }
            }
          } 
        }
        console.log(pois);

        this.pois.clients[key] = pois;
        if (this.layer=='pois') {
          // this.processPois();
        }
      },

      pause (delay) {
        return new Promise(resolve => setTimeout(resolve, delay));
      },

      onSegmentSelection (id) {
        this.segment = id;
      },

      onSegmentSave (segment) {
        _.set(this.segments, segment.id, segment);
      },

      onSegmentDelete (id) {
        // _.set(this.segments, segment.id, segment);
      },
    },

    mounted () {
      this.cards.hourly.chart.label = this.metricLabel(this.metric);
    }
  }
</script>
